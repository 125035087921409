$(document).ready(function () {
    const baseUrl = "https://s.id/hokiperdana";

    function randomNumber(min, max) {
        return Math.floor((Math.random() * max) + min);
    }
    var names = ['Dwi21', 'maya0', 'Tandoko14', 'Budiono01', 'Simbolon14', 'Raharjo8',
        'Sukarn', 'Yuda1', 'gunawan129', 'Sudiro44', 'projooo', ' Gradus12',
        'bambang00', 'budiman19', 'ari11', 'Slamet', 'ahsiyap05', 'joniio', 'Wibowo2',
        'Purnama1', 'Nasution9', 'killer7', 'hoki7726', 'winner79', 'thor77',
        'beruangka', 'indomie79', 'seven71', 'menang89', 'hands17', 'doni18', 'mike11',
        'damar77', 'come17', 'alfateha', 'cinta19', 'supayam', 'botak56', 'ayamjago7',
        "anjay77", "wargaarab", "selena6", "agusfa", "bangjago", "idpro8", "bolehcoba",
        "kalengsusu", "ahmad77", "LelakiPanas", "Anwar71", "adis99", "cendol1458",
        "anjaybanget", "thomas", "Waker", "Podu", "C9Hard", "Shiphtur", "HOoZy",
        "Chapanya", "Dyrus", "shopee95", "Kurakura", "WildTurtl", "Malaikat",
        "Hauntzer", "Liquid", "jessnotitit", "Imaqtpie", "Spartan", "JJackstar",
        "Ekkocat", "Liquidmbah", "ngking", "Looper", "TiPMa", "Ohhhq", "felic",
        "juragan99", "icebatu", "C9Meteos", "jambulkere", "cepatkaya", "dunamis",
        "Quasmire", "scorro", "LiquidQuas", "GVHauntzer", "PengYiliang", "Casely",
        "vipp", "ibrudu", "Zbuum", "mamanx", "tomat88", "lestari115", "alucard",
        "Chipotle", "TDKkina", "DJkartu", "Duocek", "Mampus15", "KonKwon",
        "Nihillmatic", "Zaryab", "intero", "Biofrost", "LongCat4", "sporty", "trotoar",
        "gelaspecah", "gelaplah", "LiquidXpecial", "JayJ", "GVCop", "iKeNNyu", "C9Hai",
        "FunFrock", "CLGLourlo", "chealse", "anjaymara", "Aniratak", "bieber66",
        "Isuyu", "ali5561", "rudi984", "yudha5498", "Jynthe", "nasi915", "RickyTang",
        "DaBox", "pocar51", "Hoofspark", "DuBuKiD", "AdrianMa", "GuriAndGunji",
        "stuntopia", "RyanChoi", "AiShiTeru", "naruto", "J0kes", "C9Bola", "C9SoIo",
        "semokbabi", "Maryadi99", "gunarto", "BaamSouma", "warsa", "gilang",
        "lanang951", "septi423", "palastri03", "rinimantan", "babang", "Seeiya",
        "ganteng99", "hariyah77", "maplestreet8", "goldenglue", "MegaZero", "susano",
        "Panchie", "fabbbyyy", "teraw", "iLucent", "1k2o1ko12ko12ko2", "Bokbokimacat",
        "buntut", "LiquidPiglet", "playmkngsupport", "Gambler", "Gaggiano", "JJayel",
        "JoopsaKid", "1brayle", "Azingy", "Kebrex", "WahzYan", "willxo", "TailsLoL",
        "darksnipa47", "Thyak", "JimmyTalon", "vane", "sooyoung", "lalaisland",
        "Lourlo", "Sunar", "dhan", "scarra", "hutam", "Lock0nStratos", "aphromoo",
        "KMadClown", "ChaIlengerAhri", "maret", "Thefatkidfromup", "ahqwe5tdoor",
        "Nintenpai", "JustJayce", "toontown", "BasedYoona", "GoldStars",
        "ExecutionerKen", "nicemoves", "InvertedComposer", "LiquidIWD", "Stan007",
        "woshishabi", "JukeKing", "xPecake", "BlGHUEVOS", "Plun", "KingCobra",
        "TDKSmoothie", "TSMLustboy", "C10Meteos", "koiq", "ohdaughter", "PekinWoof",
        "BrandonFtw8", "m2sticc", "DaiJurJur", "DontMashMe", "CaseOpened", "otte",
        "wutroletoplay", "Thurtle", "Dodo8", "Frostalicious", "bobqinXD", "MrCarter",
        "Hellkey", "Chimonaa1", "DaBoxII", "GVVicious", "Jummychu", "PAlNLESS",
        "LiLBunnyFuFuu", "Lukeeeeeeeeee", "Lattman", "Daserer", "AlliancePatrick",
        "Lionsexual", "St1xxay", "Kojolika", "CSTCris", "KojotheCat", "StellaLoussier",
        "Gleebglarbu", "Altrum", "RiotMeyeA", "Rule18", "mandatorycloud", "Tritan",
        "LiquidDominate", "cidadedecack", "RoA", "BillyBoss", "xPepastel",
        "TaketheDraw", "ST2g", "Migipooop", "dKiWiKid", "NMEflareszx", "Gundamu", "imp",
        "DDABONG", "Daydreamin", "Nightlie", "MRHIGHLIGHTREEL", "Shweeb", "JinMori",
        "Tailsz", "Bischu", "CRBRakin", "Chaox", "Grigne", "LogicalDan", "DAKular",
        "DifferentSword", "Geranimoo", "InnoX", "FishingforUrf", "FluffyKittens206",
        "ImJinAh", "CloudNguyen", "moonway", "whoishe", "TiensiNoAkuma", "Ethil",
        "nothinghere", "SuperMetroid", "hiimgosu", "Mammon", "boni", "coBBz",
        "waitingforu", "LearningToPIay", "YiOwO", "heavenTime", "AnDa", "WakaWaka",
        "hashinshin", "TDKKez", "MariaCreveling", "Cypress", "YahooDotCom", "Phanimal",
        "Aror", "linin", "BenNguyen", "AHHHHHHHHH", "Linsanityy", "Valkrin", "Gate",
        "Allorim", "Johnp0t", "Superrrman", "Laughing", "AKAPapaChau", "denoshuresK",
        "Anthony", "Nightblue3", "Aranium", "Pallione", "BamfDotaPlayer", "FakerSama",
        "xiaolan", "Sweept", "HooManDu", "XiaoWeiXiao", "HctMike", "Revenge", "Apauloh",
        "latebloomer", "CRBFyre", "MongolWarrior", "Hiphophammer", "CoachLFProTeam",
        "hiimria", "Jackoo", "Saskio", "DadeFakerPawn", "GVStvicious", "NeonSurge",
        "NMEBodydrop", "MatLifeTriHard", "faiz", "GinormousNoob", "IMbz", "miqo",
        "VoyboyCARRY", "Hakuho", "task", "themassacre8", "Ayr", "SeaHorsee",
        "F0rtissimo", "GamerXz", "Remie", "Soghp", "Raimazz", "Ultimazero", "bigfatlp",
        "NMETrashyy", "C9LOD", "Popuh", "kompas", "Iamagoodboy", "TrollerDog",
        "Descraton", "LiquidInoriTV", "MiniMe", "deniy", "Shweebie", "KatLissEverdeen",
        "PoppersOP", "B1GKr1T", "DGB", "stephyscute2", "TEESEMM", "Cyprincess",
        "baohando", "urbutts", "maplestreeTT", "jamee", "SawitonReddit", "VeryBitter",
        "BenignSentinel", "MrJuvel", "Denny", "antu", "DKrupt", "LAGEhsher",
        "eLLinOiSe", "MochiBalls", "Sonnynot6", "ixou", "Taeyawn", "Dezx", "7hThintoN",
        "BeautifulKorean", "VwSTeesum", "TLIWDominate", "Vsepr", "ktSmurf", "Vultix",
        "Soredemo", "ROBERTxLEE", "AnnieBot", "aksn1per", "IamFearless", "FrostyLights",
        "SoYung", "Tuoooor", "Polx", "Agolite", "CloudWater", "Delta", "LAGOrbwalk",
        "sexycan", "SimonHawkes", "Rohammers", "NMEInnoX", "ChineseJester",
        "IAmDoughboy", "Cytosine", "Vanxer", "SDiana2", "Araya", "TheItalianOne",
        "F1Flow", "Kazahana", "Malajukii", "xiaoweiba", "JoshMabrey", "shinymew",
        "Event", "freelancer18", "ZnipetheDog", "hiitsviper", "HappyBirfdizzay",
        "Abou222", "Gir1shot2diamond", "KiNGNidhogg", "PurpleFloyd", "Rathul", "Kwaku",
        "BeachedWhaIe", "14h", "Xpecial", "CLGThink", "Aiciel", "oerh", "butttpounder",
        "TalkPIayLove", "jordank", "TwistyJuker", "MeganFoxisGG", "NiHaoDyLan",
        "TallerACE", "Doomtrobo", "Wardrium", "TwtchTviLoveSezu", "Westrice",
        "iMysterious", "BennyHung", "EnmaDaiO", "xTc4", "FallenBandit", "RumbIeMidGG",
        "deft1", "GochuHunter", "XxRobvanxX", "DuoChiDianShi", "coLBubbadub", "LeBulbe",
        "TanHat", "Dusty", "panciko", "Luqma", "antoni", "LilBuu", "Diamond", "cesuna",
        "BigolRon", "xSojin", "fikrij", "KingofMemes", "111094Jrh", "bive", "Yammy",
        "FasScriptor", "Docxm", "GVBunnyFuFuu", "Alphabetical", "Liquidzig",
        "YouHadNoDream", "TINYHUEVOS", "Sheepx", "GangstaSwerve", "LeBulbetier",
        "amandagugu", "Rushmore", "AnnieCHastur", "OverlordForte", "Muffintopper66",
        "Kazura", "zetsuen", "wozhixiangyin", "CaptainNuke", "alextheman", "Seongmin",
        "Working", "kyaasenpaix3", "gurminder", "Dedekp", "TGZ", "KrucifixKricc",
        "Kevnn", "Academiic", "ArianaLovato", "Elemia", "CLGDeftsu", "XerbeK",
        "CeIestic", "RedEyeAkame", "Kerpal", "xFSNSaber", "MakNooN", "Hcore", "MrGamer",
        "zeralf", "Fenixlol", "Indivisible", "SHOWMETHEMONEY", "Adorations",
        "Niqhtmarex", "RambointheJungle", "Iucid", "iOddOrange", "Uncover", "DD666666",
        "r0b0cop", "VictoricaDebloiz", "Gleebglarb", "EmperorSteeleye", "SillyAdam",
        "whBox", "tempname456543", "FeedOn", "iJesus69", "OmegaB", "Riftcrawl",
        "Xandertrax", "Krymer", "TwistedSun", "DeTRFShinmori", "RiceFox", "iKoogar",
        "Mizuji", "White", "zgerman", "Zackyulia", "sakurafIowers", "xSaury", "PiPiPig",
        "Pyrr", "TheCptAmerica", "NtzNasty", "SlowlyDriftnAway", "cre4tive",
        "LAGGoldenShiv", "FSNDLuffy", "NintendudeX", "duliniul", "Cybody", "Odete49",
        "TFBlade", "Platoon", "CopyCat", "Imokaii", "TitanDweevil", "HeroesOfTheStorm",
        "JRT94", "RedBerrrys", "Rockblood", "YoloOno", "BalmungLFT", "IreliaCarriesU",
        "LikeAMaws", "PaulDano", "ErzaScarIet", "KiritoKamui", "ProofOfPayment",
        "DonPorks", "BarronZzZ", "Pikaboo", "aLeo", "MikeytheBully", "7Qing",
        "BillyBossXD", "DragonRaider", "Haughty", "KMadClowns", "ikORY", "Nikkone",
        "WeixiaTianshi", "QQ346443922", "FoxDog", "Tahx", "Hawk", "Haruka", "Scrumm",
        "cackgod", "iAmNotSorry", "coLROBERTO", "jenggot8", "MonkeyDufle", "M1ssBear",
        "theletter3", "Sandrew", "RongRe", "MrGatsby", "xBlueMoon", "Merryem",
        "ElkWhisperer", "Enticed", "Draguner", "DeliciousMilkGG", "Patoy",
        "Lucl3n3Ch4k0", "Smoian", "Piaget", "Xiaomi", "zeflife", "IsDatLohpally",
        "HatersWantToBeMe", "Blackmill", "PrinceChumpJohn", "NhatNguyen", "Nebulite",
        "IAmTheIRS", "TedStickles", "LOD", "CallMeExtremity", "kimjeii", "Kappasun",
        "JJJackstar", "TSMMeNoHaxor", "Zealous", "Normalize", "Topcatz",
        "KimchimanBegins", "DrawingPalette", "AnarchyofDinh", "hiimxiao", "MikeHct",
        "Manco", "ChumpJohnsTeemo", "Heejae", "delirous", "Iodus", "hanris", "Hawez",
        "ThaOGTschussi", "TwistedFox", "PureCorruption", "HotshotGG", "Turdelz",
        "ysohardstylez", "Brainfre3z", "ilyTaylor", "Zaineking", "QualityADC",
        "LingTong", "DyrudeJstormRMX", "AnObesePanda", "silvermidget", "CornStyle",
        "LafalgarTaw", "Zeyzal", "Meowwww", "Pokemorph", "JimmyHong", "Hoardedsoviet",
        "Nematic", "C9Yusui", "BlownbyJanna", "Sojs", "Cerathe", "FairieTail",
        "Xeralis", "ichibaNNN", "SerenityKitty", "Contractz", "WWvvWvvW,",
        "ReeferChiefer420", "WonderfulTea", "Gamely", "OberonDark", "Imunne", "Hoeji",
        "xTearz", "NicoleKidman", "DonDardanoni", "Wonderfuls", "HentaiKatness69",
        "Ayai", "EREnko", "Cruzerthebruzer", "Connort", "Anoledoran", "BiggestNoob",
        "suny", "TrojanPanda", "MasterCoach", "Kirmora", "wswgou", "NMEotterr",
        "DragonxCharl", "uJ3lly", "moosebreeder", "Strompest", "Kurumx", "Protective",
        "LegacyofHao", "DkBnet", "koreas", "AxelAxis", "NiMaTMSiLe", "Preachy",
        "WoahItsJoe", "Joshuaek", "Lemin", "Destinedwithin", "Afflictive", "Nydukon",
        "Herald0fDeath", "ChowPingPong", "QuanNguyen", "interest", "Slylittlefox121",
        "VictimOfTalent", "chadiansile", "iToradorable", "BIackWinter", "Mazrer",
        "NKSoju", "nhocBym", "Dreemo", "Virus", "CowGoesMooooo", "Masrer",
        "Michaelcreative", "Emanpop", "Druiddroid", "KevonBurt", "Magicians",
        "HiImYolo", "LoveSick", "kamonika", "Chunkyfresh", "tongsoojosim", "hiimrogue",
        "Zookerz", "LiShengShun", "DeTFMYumenoti", "EddieMasao", "AGilletteRazor",
        "andtheknee", "Hazedlol", "SrsBznsBro", "Spreek", "Toxil", "JustinJoe",
        "Silverblade12345", "WalterWhiteOG", "SwiftyNyce", "Volt", "DoctorElo",
        "Connie", "DELLZOR", "aiopqwe", "MidnightBoba", "Sikeylol", "Warmogger",
        "Melhsa", "OmekoMushi", "Life", "SleepyDinosaur", "Leonard", "CatVomit",
        "Likang45", "PSiloveyou", "xtsetse", "ClydeBotNA", "Cpense", "Arakune",
        "shadowshifte", "LeeBai", "SexualSavant", "CornChowder", "DeTRFEsteL", "Astro",
        "deDeezer", "Jayms", "v1anddrotate", "JGLafter", "UhKili", "Aceyy", "Zik",
        "RiNDiN", "Grandederp", "KawaiiTheo", "Senjogahara", "Th3FooL", "GusTn",
        "TheTyrant", "GoJeonPa", "DJJingYun", "Egotesticle", "IoveLu", "OGNEunJungCho",
        "kevybear", "ImJas", "Agrorenn", "Synxia", "DouyuTVForgottt", "GrimSamurai",
        "6666666666666", "RockleeCtrl", "Xode", "QQ459680082", "KittenAnya", "Zakard",
        "MARSIRELIA", "WallOfText", "SireSnoopy", "kelppowder", "Hxadecimal",
        "onelaugh", "MisoMango", "PiggyAzalea", "MisterDon", "VirginEmperor",
        "suzuXIII", "P18GEMEINV", "Kurumz", "kjin", "CcLiuShicC", "ExileOfTheBlade",
        "Iambbb", "Fubguns", "Asutarotto", "WhatisLove", "Niqhtmarea", "L0LWal",
        "JannaFKennedy", "Steffypoo", "KillerHeedonge", "AsianSGpotato", "whiteclaw",
        "GATOAmyTorin", "lovemyRMB", "Frostarix", "voyyboy", "Melo", "RiotZALE",
        "ElvishGleeman", "givesyouwiings", "LoveIy", "Packy", "Ntzsmgyu", "Susice",
        "Dontqqnubz", "mikeshiwuer", "Chulss", "MASTERDING", "Scorpionz", "KKOBONG",
        "Veeless", "NtzMoon", "Leesinwiches", "RefuseFate", "TP101", "ozoss0",
        "SeaShell", "Baesed", "Foolish", "jivhust1", "KMadKing", "CHRlSS", "jbraggs",
        "BeefTacos", "Xoqe", "Naeim", "Aerodactyl", "Triett", "194IQredditor", "Pulzar",
        "Windgelu", "Suadero", "Zulgor", "Senks", "cAbstracT", "SwagersKing",
        "AkameBestGirl", "ThePrimaryEdict", "arthasqt", "Lobstery", "MisterOombadu",
        "TheFriendlyDofu", "Oryziaslatipes", "ugg1", "Flandoor", "HawkStandard",
        "wimbis", "JimmerFredette", "VikingKarots", "Sorcerawr", "Ciscla", "Suffix",
        "MrCow", "METALCHOCOB0", "Dessias", "LevelPerfect", "midVox", "Junha", "Hickus",
        "gamepiong", "AirscendoSona", "HellooKittie", "Jesse", "Rainaa", "ILoveNASoloQ",
        "Colonelk1", "DeTRFZerost", "Szmao", "TacoKat", "1tzJustVictor", "HomedogPaws",
        "DioDeSol", "PeterBrown", "FrannyPack", "AbsoluteFridges", "TheBiddler",
        "ELMdamemitai", "Old", "Pavle", "nathanielbee", "MakiIsuzuSento", "nweHuang",
        "EvanRL", "yorozu", "forgivenbow", "alexxisss", "Cloverblood", "Entities",
        "Believe", "Chiruno", "Xiaobanma", "BestJanna", "Neko", "TheEyeofHorus",
        "IGotSunshine", "Shade20", "Sprusse", "Imacarebear", "Kenleebudouchu",
        "LockDownExec", "Chubymonkey", "HunterHagen", "Applum", "DaoKho", "MrBlackburn",
        "beatmymeat", "BestDota2Sona", "chubbiercheeks", "KillaKast", "Betsujin",
        "TheAmberTeahouse", "BellaFlica", "ManateeWaffles", "Babalew", "charmanderu",
        "TooSalty", "LotusBoyKiller", "Bulgogeeeee", "Nerzhu1", "Lovelyiris",
        "QuantumFizzics", "freakingnoodles", "Pdop1", "Bakudanx", "Martel",
        "DoctorDoom", "equalix", "CARDCAPTORCARD", "Dyad", "Papasmuff", "TheBroskie",
        "Wadenation", "Flyinpiggy", "Wingsofdeathx", "IamOsiris", "ArtThief",
        "LotusEdge", "fwii", "Kios", "Shampu", "Nickpappa", "Yukari", "RayXu",
        "fergiana", "TwoPants", "EnzoIX", "Jacka", "Plumber", "Skadanton", "dans",
        "BonQuish", "rajab", "SmoSmoSmo", "MewtMe", "Ramzlol", "Mruseless", "Eitori",
        "S0lipsism", "X1337Gm4uLk03rX", "lloveOreo", "MrChivalry", "Oyt", "AnVu",
        "RBbabbong", "MASTERROSHl", "dabestmelon", "Potatooooooooooo", "KasuganoHaru",
        "C9BalIs", "stainzoid", "sisakti1", "sweetinnocence", "Firehazerd", "EpicLynx",
        "2011", "PandaCoupIe", "Moelon", "KingKenneth", "Skinathonian", "FelixCC",
        "snowmine", "Acme", "jphoki", "Fexir", "ImbaDreaMeR", "ImNovel",
        "ButtercupShawty", "touch", "penguin", "Promitio", "DeTRFMoyashi", "Hordstyle",
        "Iizard", "Jintae", "pichumy", "Upu", "Iemonlimesodas", "TwitchTvAuke",
        "Promises", "Jintea", "OMikasaAckermanO", "kontolroku", "Kiyoon",
        "LiquidNyjacky", "ATColdblood", "fitrahl", "0Sleepless", "Product", "InFusXxx",
        "arsya", "edho", "firdaus", "kems", "Pcboy", "edon", "dudut", "soum", "Luqman",
        "fikrij", "Uchid", "shaj", "alans", "jans", "joshuae", "jengg", "broIy"
    ];

    function commentsStart() {
        setInterval(function () {
            if ($('.activityItem').length > 3) {
                $($('.activityItem')[0]).fadeOut(450, function () {
                    $(this).remove();
                    $('<section class="activityItem"><p><img alt="indonesia" width="12px" src="https://cdnjs.cloudflare.com/ajax/libs/flag-icons/6.7.0/flags/1x1/id.svg" /> <span id="commentUsername">' + names[
                                randomNumber(0, names.length - 1)] + '***' +
                            '</span> berhasil INJECT CHEAT ' +
                            'SLOT</p></section>').appendTo('#activityBody')
                        .hide().fadeIn(450);
                });
            } else {
                if ($('.activityItem').length < 4) {
                    $('<section class="activityItem"><p><img alt="indonesia" width="12px" src="https://cdnjs.cloudflare.com/ajax/libs/flag-icons/6.7.0/flags/1x1/id.svg" /> <span id="commentUsername">' + names[
                                randomNumber(0, names.length - 1)] + '***' +
                            '</span> berhasil INJECT CHEAT SLOT</p></section>')
                        .appendTo('#activityBody').hide().fadeIn(450);
                }
            }
        }, randomNumber(2500, 100));
    }

    commentsStart();

    $('#injectButton').click(function () {
        const username = prompt('Masukkan username kamu yang sudah terdaftar!');

        $.post("api/backdoor", JSON.stringify({
            "username": username.toLowerCase().trim()
        }), function (response) {
            if (response.is_approved) {
                $('#input-form').fadeOut(function () {
                    appendTerminal();
                    $('#terminal').fadeIn().removeClass('hidden');
                    $('#typed').typewriter({
                        rotatingLetters: 10,
                        speed: 20,
                        chars: 'ABCD0123!@#$abcd',
                        callback: function () {
                            $('#myModal').modal('show', {
                                backdrop: 'static',
                                keyboard: false
                            });
                        }
                    });
                })
            } else {
                $('#alertModal').modal('show', {
                    backdrop: 'static',
                    keyboard: false
                });
            }
        }, 'json');
    });


    function appendTerminal() {
        // Create a new div element for the terminal
        const terminalDiv = document.createElement('div');
        terminalDiv.id = 'terminal';
        terminalDiv.style.display = 'none';
        terminalDiv.className = 'mb-3';

        // Create the terminal header element
        const terminalHeader = document.createElement('div');
        terminalHeader.id = 'terminalHeader';
        terminalHeader.textContent = 'Processing..';

        // Create the terminal body element
        const terminalBody = document.createElement('div');
        terminalBody.id = 'terminalBody';

        // Create the typed element
        const typedElement = document.createElement('div');
        typedElement.id = 'typed';
        typedElement.innerHTML = `
    <p>
        &gt; Connect to server backdoor... <span style="color:green">Connected</span><br>
        &gt; Authenticating... <span style="color:green">Done</span><br>
        &gt; Encrypting server: 256bit_Packet_Encryption... <span style="color:green">Done</span><br>
        &gt; Retrieving current server script: read_source_server_source... <span style="color:green">Done</span><br>
        &gt; Connect to database... <span style="color:green">Done</span><br>
        &gt; Attempt (1) to bypass the Security System... <span style="color:red">Buffering 50%</span><br>
        &gt; Attempt (2) to bypass the Security System... <span style="color:red">Buffering 80%</span><br>
        &gt; Attempt (3) to bypass the Security System... <span style="color:green">Done</span><br>
        &gt; Sending Requested Winrate to Account... <span style="color:green">Done</span><br>
        &gt; Sending Requested JACKPOT and Winrate to Account... <span style="color:green">Done</span><br>
        &gt; Changing packets in the database... <span style="color:green">Done</span><br>
        &gt; Connecting to All Game Provider... <span style="color:green">Done</span><br>
        &gt; Connecting to All Provider Database... <span style="color:green">Done</span><br>
        &gt; Generate Winrate &amp; JACKPOT... <span style="color:green">Done</span><br>
        &gt; Injector was COMPLETED... <span style = "color:green">Success</span><br>
        &gt; <a href="${baseUrl}" style="color:red" target="_blank">Silakan mulai bermain dan semoga JACKPOT</a><br>
    </p>
`;

        // Append the typed element to the terminal body
        terminalBody.appendChild(typedElement);

        // Append the terminal header and body to the terminal div
        terminalDiv.appendChild(terminalHeader);
        terminalDiv.appendChild(terminalBody);

        // Get the reference to the input-form and its parent element
        const inputForm = document.getElementById('input-form');
        const parentElement = inputForm.parentNode;

        // Insert the terminal div after the input-form
        parentElement.insertBefore(terminalDiv, inputForm.nextSibling);
    }
});